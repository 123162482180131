import { FC } from 'react'

import {
    EmissionOrderStatus,
    IEmissionOrder,
    OrderTypeEnum,
    finalEmissionStatuses,
} from '@dltru/dfa-models'
import { BodyDate, BodyRoundedBlock, BodyTag, getOrderStatusTagProps } from '@dltru/dfa-ui'

import { statusDescription, statusText } from '../../helper'

import { PaymentsWarnWithBankDetails } from './PaymentsWarnWithBankDetails'

export const EmissionOrderStatusBlock: FC<
    Pick<
        IEmissionOrder,
        | 'created_at'
        | 'status'
        | 'executed_at'
        | 'asset_ticker_symbol'
        | 'emitter_id'
        | 'asset_id'
        | 'expired_at'
        | 'canceled_at'
    >
> = ({
    created_at,
    status,
    executed_at,
    asset_ticker_symbol,
    emitter_id,
    asset_id,
    expired_at,
    canceled_at
}) => {
    const dateInSeconds = executed_at || expired_at || canceled_at
    const endDate = new Date(dateInSeconds ? dateInSeconds * 1000 : 0)

    const isFinallyOrder = status && finalEmissionStatuses.includes(status)

    const rightBlock = isFinallyOrder ? (
        <>
            <BodyTag tag={getOrderStatusTagProps(status || '', OrderTypeEnum.emission)} />
            <BodyDate time date={endDate} />
        </>
    ) : (
        <>
            <p className="card-modal__body-text--12">{statusText[status] ?? ''}</p>
            <p className="card-modal__body-text--16">{statusDescription[status] ?? ''}</p>
        </>
    )

    return (
        <BodyRoundedBlock
            success={status === EmissionOrderStatus.executed}
            left={
                <>
                    <p className="card-modal__body-text--12">
                        {isFinallyOrder
                            ? 'Подача заявки'
                            : `Подача ${
                                  status === EmissionOrderStatus.waiting_for_emitter_accept
                                      ? 'Заявки на приобретение'
                                      : 'заявки на покупку'
                              }`}
                    </p>
                    <BodyDate time date={created_at * 1000 || new Date()} />
                </>
            }
            right={rightBlock}
        >
            <PaymentsWarnWithBankDetails
                status={status}
                assetTickerSymbol={asset_ticker_symbol}
                emitterId={emitter_id}
                assetId={asset_id}
            />
        </BodyRoundedBlock>
    )
}
