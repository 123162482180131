import { ISecondaryOrder, ISecondaryOrderShort } from '@dltru/dfa-models'
import { CardModal, getModalTradeHeaderData } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDfaBalanceById, getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { SecondaryOrderDetailsModalBody } from './SecondaryOrderDetailsModalBody'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    order?: ISecondaryOrderShort | ISecondaryOrder
    secondModal?: boolean
    onlyDetails?: boolean
}

export const SecondaryOrderDetailsModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    order,
    secondModal,
    onlyDetails,
}) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)

    const reduxDispatch = useDispatch()
    useEffect(() => {
        if (isModalVisible) {
            if (order?.asset_id) {
                if (order.asset_id !== dfaDetails?.id) {
                    reduxDispatch(getDfaById({ dfaId: order.asset_id, withBalance: true }))
                } else {
                    reduxDispatch(getDfaBalanceById({ dfaId: order.asset_id }))
                }
            }
        }
    }, [isModalVisible, order?.asset_id])

    return (
        <CardModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            headerData={getModalTradeHeaderData({
                ...(dfaDetails || {}),
                emitter_full_name: (order as ISecondaryOrder)?.emitter_full_name,
            })}
            secondModal={secondModal}
            width={540}
            BodyRenderProps={
                <SecondaryOrderDetailsModalBody
                    setIsModalVisible={setIsModalVisible}
                    secondaryDfaDetails={order}
                    onlyDetails={onlyDetails}
                />
            }
        />
    )
}
