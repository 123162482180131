import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IEmissionOrder, IEmissionOrderDeal } from '@dltru/dfa-models'
import { CardModal, getModalTradeHeaderData } from '@dltru/dfa-ui'

import { getDfaBalanceById, getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { EmissionOrderDetailsModalBody } from './EmissionOrderDetailsModalBody'

export interface ITradeModal {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    order?: IEmissionOrder | IEmissionOrderDeal
    secondModal?: boolean
}

export const EmissionOrderDetailsModal: FC<ITradeModal> = ({
    isModalVisible,
    setIsModalVisible,
    order,
    secondModal,
}) => {
    const reduxDispatch = useDispatch()

    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)

    useEffect(() => {
        if (isModalVisible) {
            if (order?.asset_id) {
                if (order.asset_id !== dfaDetails?.id) {
                    reduxDispatch(getDfaById({ dfaId: order.asset_id, withBalance: true }))
                } else {
                    reduxDispatch(getDfaBalanceById({ dfaId: order.asset_id }))
                }
            }
        }
    }, [isModalVisible, order?.asset_id])

    return (
        <CardModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            secondModal={secondModal}
            headerData={getModalTradeHeaderData(dfaDetails, order?.repurchase_percent)}
            BodyRenderProps={
                order && (
                    <EmissionOrderDetailsModalBody
                        orderDetails={order}
                        setIsModalVisible={setIsModalVisible}
                    />
                )
            }
        />
    )
}
