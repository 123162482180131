export const description = (
    <>
        <p>
            Нажимая кнопку «Принять заявку», Вы в соответствии с пунктом 10.37.1 Правил направляете
            уведомление об исполнении Первым Приобретателем, указанным в графе «Инвестор»,
            обязательств по Сделке размещения, указанной в разделе «Детали покупки».
        </p>
        <p>
            Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в значении,
            предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)
