import {
    ISecondaryOrderShort,
    OrderTypeEnum,
    SecondaryOrderStatus,
    notFinalSecondaryStatuses,
} from '@dltru/dfa-models'
import { BodyDate, BodyRoundedBlock, BodyTag, getOrderStatusTagProps } from '@dltru/dfa-ui'
import { FC } from 'react'

interface IComponentProps {
    order?: ISecondaryOrderShort
}

export const SecondaryOrderStatusBlock: FC<IComponentProps> = ({ order = {} }) => {
    if (!order) {
        return null
    }
    const { created_at, executed_at, canceled_at, status } = order
    const isFinallyOrder = status && !notFinalSecondaryStatuses.includes(status)
    const finalDate = status === SecondaryOrderStatus.executed ? executed_at : canceled_at

    /*    TODO позже вернуть
    const countExpiredDay = expired_at ? dayjs.unix(expired_at).diff(dayjs(), 'days') : undefined
    const progressBlock = SecondaryOrderStatus.accepted ? (
        <>
            <p className="card-modal__body-text--12">до снятия</p>
            <p className="card-modal__body-text--16">
                {countExpiredDay ? `${countExpiredDay} ${daysLabel(countExpiredDay)}` : ''}
            </p>
        </>
    ) : null*/

    const rightBlock = isFinallyOrder ? (
        <>
            <BodyTag tag={getOrderStatusTagProps(status || '', OrderTypeEnum.secondary)} />
            {Boolean(finalDate) && <BodyDate time date={finalDate ? finalDate * 1000 : 0} />}
        </>
    ) : null

    return (
        <BodyRoundedBlock
            success={status === SecondaryOrderStatus.executed}
            left={
                <>
                    <p className="card-modal__body-text--12">Публикация на витрине</p>
                    <BodyDate time date={created_at ? created_at * 1000 : new Date()} />
                </>
            }
            right={rightBlock}
        />
    )
}
