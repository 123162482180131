import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
    EmissionOrderStatus,
    IEmissionOrder,
    IEmissionOrderDeal,
    isEmissionOrder,
} from '@dltru/dfa-models'
import {
    Alert,
    BodyCheck,
    BodyTitle,
    Box,
    Button,
    CheckIcon,
    DeleteIcon,
    Space,
} from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { acceptedByEmitter, refusedByEmitter } from '@store/orders/details'

import { TradeBuyingModal } from '../../Trade'

import { EmissionOrderStatusBlock } from './EmissionOrderStatusBlock'
import { description } from './utils'

interface ITradeModalBody {
    orderDetails: IEmissionOrder | IEmissionOrderDeal
    setIsModalVisible: (val: boolean) => void
}

export const EmissionOrderDetailsModalBody: FC<ITradeModalBody> = ({
    orderDetails,
    setIsModalVisible,
}) => {
    const reduxDispatch = useDispatch()
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)
    const userUid = useSelector(authSelector.selectUserUid)

    const emitterAccepted = () => {
        reduxDispatch(acceptedByEmitter({ uuid: orderDetails.uuid, id: orderDetails.id }))
        setIsModalVisible(false)
    }
    const emitterRefused = () => {
        reduxDispatch(refusedByEmitter({ uuid: orderDetails.uuid, id: orderDetails.id }))
        setIsModalVisible(false)
    }

    const checkList = [
        {
            label: 'Инвестор',
            Content: () => (
                <Link to={`/clients/${orderDetails?.user_id}`}>
                    {isEmissionOrder(orderDetails)
                        ? orderDetails?.user_name
                        : orderDetails?.user_full_name}
                </Link>
            ),
        },
        {
            label: 'Цена',
            price: orderDetails?.price_per_dfa || 0,
        },
        {
            label: 'Количество, ед.',
            price: orderDetails?.amount_dfa || 0,
            currency: 'unit',
        },
    ].map((check) => <BodyCheck {...check} key={check.label} />)

    const viewAcceptForEmitter =
        userUid === orderDetails.emitter_id &&
        orderDetails.status === EmissionOrderStatus.waiting_for_emitter_accept

    return (
        <>
            <BodyTitle title="Детали покупки" id={orderDetails?.id} />
            <div>{checkList}</div>
            <Box padding={[8, 0, 0, 0]}>
                {orderDetails && <EmissionOrderStatusBlock {...orderDetails} />}
            </Box>
            {viewAcceptForEmitter && (
                <>
                    <Box padding={[24, 0, 0, 0]}>
                        <Alert type="warning" showIcon description={description} />
                    </Box>
                    <Box padding={[24, 0, 0, 0]} align="end">
                        <Space size={16}>
                            <Button
                                size="large"
                                color="default"
                                icon={<DeleteIcon />}
                                borderRadius={16}
                                onClick={emitterRefused}
                            >
                                Отклонить заявку
                            </Button>
                            <Button
                                size="large"
                                type="primary"
                                icon={<CheckIcon />}
                                borderRadius={16}
                                onClick={emitterAccepted}
                            >
                                Принять заявку
                            </Button>
                        </Space>
                    </Box>
                </>
            )}
            {isCardModalVisible && (
                <TradeBuyingModal
                    isModalVisible={isCardModalVisible}
                    setIsModalVisible={setIsCardModalVisible}
                    amount={orderDetails?.amount_dfa || 0}
                />
            )}
        </>
    )
}
