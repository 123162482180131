import {
    ISecondaryOrder,
    ISecondaryOrderShort,
    OrderTypeEnum,
    SecondaryOrderStatus,
    finalSecondaryStatuses,
    secondaryOrderStatusCancelable,
} from '@dltru/dfa-models'
import { BodyTitle, Box, Button, DeleteIcon, ShoppingCart, Space } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'

import { SecondaryRevokeModal } from '../../Revoke/Secondary'
import { SecondaryTradeBuyingModal } from '../../Trade'
import { CommissionAlertBlock } from './CommissionAlertBlock'
import { SecondaryCheckList } from './SecondaryCheckList'
import { SecondaryOrderStatusBlock } from './SecondaryOrderStatusBlock'

interface IComponentProps {
    secondaryDfaDetails?: ISecondaryOrderShort | ISecondaryOrder
    setIsModalVisible: (state: boolean) => void
    onlyDetails?: boolean
}

export const SecondaryOrderDetailsModalBody: FC<IComponentProps> = ({
    secondaryDfaDetails,
    setIsModalVisible,
    onlyDetails,
}) => {
    const [isRevokeConfirmShow, setIsRevokeConfirmShow] = useState(false)
    const uuid = useSelector(authSelector.selectUserUid)
    const isMyOrder = uuid === secondaryDfaDetails?.user_id
    const isBuying = secondaryDfaDetails?.order_type === OrderTypeEnum.sell
    const isOrderWithAction =
        !isMyOrder &&
        secondaryDfaDetails?.status !== SecondaryOrderStatus.revoked &&
        secondaryDfaDetails?.status !== SecondaryOrderStatus.executed
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)

    const endDate = new Date(secondaryDfaDetails?.created_at || 0)
    endDate.setDate(endDate.getDate() + 5)

    const finalStatus =
        secondaryDfaDetails?.status && finalSecondaryStatuses.includes(secondaryDfaDetails.status)

    const showCancelBtn =
        isMyOrder &&
        secondaryDfaDetails?.status &&
        secondaryOrderStatusCancelable.includes(secondaryDfaDetails?.status)

    const buyByIssuer = isBuying && (secondaryDfaDetails as ISecondaryOrder).emitter_id === uuid

    return (
        <>
            <BodyTitle
                title={`Детали ${isBuying ? 'продажи' : 'покупки'}`}
                id={secondaryDfaDetails?.id}
            />
            <SecondaryCheckList data={secondaryDfaDetails} />
            <Box padding={isMyOrder ? [0, 0, 0, 0] : [24, 0, 0, 0]}>
                <SecondaryOrderStatusBlock order={secondaryDfaDetails} />
            </Box>
            {!finalStatus && <CommissionAlertBlock order={secondaryDfaDetails} />}
            <Box padding={[24, 0, 0, 0]} align="end">
                <Space size={32}>
                    {showCancelBtn && (
                        <Button
                            size="large"
                            color="default"
                            icon={<DeleteIcon />}
                            borderRadius={16}
                            onClick={() => {
                                setIsRevokeConfirmShow(true)
                            }}
                        >
                            Отозвать заявку
                        </Button>
                    )}

                    {!onlyDetails && isOrderWithAction && (
                        <>
                            {buyByIssuer && (
                                <span className="card-modal__body-text--12">
                                    Вы являетесь Эмитентом данного выпуска ЦФА.
                                </span>
                            )}
                            <Button
                                size="large"
                                type="primary"
                                icon={<ShoppingCart />}
                                borderRadius={16}
                                onClick={() => {
                                    setIsCardModalVisible(true)
                                    setIsModalVisible(false)
                                }}
                            >
                                {isBuying ? 'Купить' : 'Продать'}
                            </Button>
                        </>
                    )}
                </Space>
            </Box>
            <SecondaryTradeBuyingModal
                isModalVisible={isCardModalVisible}
                setIsModalVisible={setIsCardModalVisible}
                amount={isMyOrder ? secondaryDfaDetails?.original_amount_dfa : 0}
                rest_amount_dfa={secondaryDfaDetails?.rest_amount_dfa || 0}
                price_per_dfa={secondaryDfaDetails?.price_per_dfa || 0}
                buying={
                    (!isMyOrder && secondaryDfaDetails?.order_type === OrderTypeEnum.sell) ||
                    (isMyOrder && secondaryDfaDetails?.order_type === OrderTypeEnum.buy)
                }
                selling={
                    (!isMyOrder && secondaryDfaDetails?.order_type === OrderTypeEnum.buy) ||
                    (isMyOrder && secondaryDfaDetails?.order_type === OrderTypeEnum.sell)
                }
                accepted={!isMyOrder}
                assetId={secondaryDfaDetails?.asset_id}
                orderId={secondaryDfaDetails?.id}
                paymentCollectType={secondaryDfaDetails?.payment_collect_type}
            />

            <SecondaryRevokeModal
                setIsParentModalVisible={setIsModalVisible}
                setIsModalVisible={setIsRevokeConfirmShow}
                isModalVisible={isRevokeConfirmShow}
                order={secondaryDfaDetails}
            />
        </>
    )
}
