import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EmissionOrderStatus, IBankDetails, SettlementsType } from '@dltru/dfa-models'
import { Alert, BankAccountDetailsModal, Box } from '@dltru/dfa-ui'

import { profileSelector } from '@store/profiles/details/selectors'

import api from '@services/api'

import { getBankDetailsByUserId } from '@pages/DFAEmission/DFAEmissionCardPage/components/Footer/Actions/BankAccountDetails/utils'

interface IPaymentsWarnWithBankDetailsProps {
    status: EmissionOrderStatus
    assetTickerSymbol: string
    emitterId: string
    assetId: number
}

export const PaymentsWarnWithBankDetails: FC<IPaymentsWarnWithBankDetailsProps> = ({
    status,
    assetTickerSymbol,
    emitterId,
    assetId,
}) => {
    const userFullName = useSelector(profileSelector.userFullName)

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [bankDetails, setbankDetails] = useState<IBankDetails | undefined>()
    const [isAvailable, setIsAvailable] = useState(false)

    useEffect(() => {
        const getDfa = async () => {
            try {
                // костыль, запрашиваем полностью ЦФА только для определения типа issue_settlements_type, хорошо бы получать со стороны BE
                const { data } = await api.lib.getDfaById(Number(assetId))
                if (data?.item) {
                    const isAvailable =
                        data?.item.issue_settlements_type === SettlementsType.direct &&
                        status === EmissionOrderStatus.waiting_for_emitter_accept
                    setIsAvailable(isAvailable)
                } else {
                    throw new Error('error dfa load')
                }
            } catch (e) {
                setbankDetails(undefined)
            }
        }

        if (assetId && status) {
            getDfa()
        }
    }, [assetId, status])

    useEffect(() => {
        const getBankData = async () => {
            const bankData = await getBankDetailsByUserId(emitterId)
            setbankDetails(bankData)
        }

        if (emitterId && isAvailable) {
            getBankData()
        }
    }, [emitterId, isAvailable])

    const paymentTarget = (
        <>
            <p>{`Оплата в рамках сделки приобретения выпуска ${assetTickerSymbol}`}</p>
            <p>{`По заявке инвестора, ${userFullName}. НДС не облагается.`}</p>
        </>
    )
    const paymentTargetText = `Оплата в рамках сделки приобретения выпуска ${assetTickerSymbol}. По заявке инвестора, ${userFullName}. НДС не облагается.`

    if (!isAvailable) {
        return null
    }

    return (
        <Box margin={[16, 0, 0, 0]}>
            <Alert
                showIcon
                type="warning"
                message={
                    <>
                        Для оплаты в рамках сделки приобретения совершите перевод по{' '}
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                setIsModalVisible(true)
                            }}
                        >
                            реквизитам Эмитента
                        </a>
                    </>
                }
            />

            {isModalVisible && bankDetails ? (
                <BankAccountDetailsModal
                    shift
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    title="Банковские реквизиты Эмитена выпуска ЦФА"
                    bodyText="Для оплаты в рамках сделки приобретения совершите банковский перевод по указанным реквизитам."
                    paymentTargetText={paymentTargetText}
                    paymentTarget={paymentTarget}
                    recipient={bankDetails.recipient}
                    recipientInn={bankDetails.recipient_inn}
                    bankDetails={bankDetails}
                />
            ) : null}
        </Box>
    )
}
