import {
    CollectType,
    ISecondaryOrder,
    ISecondaryOrderShort,
    ServiceCodeFee,
} from '@dltru/dfa-models'
import { Alert, Box, LinkToTariff, Price } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { getCalculateFeeHelper } from '@store/helper'

interface IComponentProps {
    order?: ISecondaryOrderShort | ISecondaryOrder
}

export const CommissionAlertBlock: FC<IComponentProps> = ({ order }) => {
    const user_id = useSelector(authSelector.selectUserUid)

    const [feePaymentOrder, setFeePaymentOrder] = useState(0)
    const isBankCollectType = order?.payment_collect_type === CollectType.bank_account
    const getCurrentFeePaymentOrder = async () => {
        const amount = await getCalculateFeeHelper({
            service_code: ServiceCodeFee.paymentOrder,
            user_id,
            operation_amount: (order?.original_amount_dfa || 0) * (order?.price_per_dfa || 0),
        })
        setFeePaymentOrder(amount?.item?.FeeAmount)
    }
    useEffect(() => {
        if (isBankCollectType) {
            getCurrentFeePaymentOrder()
        } else {
            setFeePaymentOrder(0)
        }
    }, [isBankCollectType])
    if (!isBankCollectType) {
        return null
    }
    return (
        <Box margin={[16, 0]}>
            <Alert
                description={
                    <>
                        Согласно{' '}
                        <b>
                            <LinkToTariff />
                        </b>
                        , дополнительно взимается плата за исполнение платежного поручения в размер
                        <Price price={feePaymentOrder / 100} />
                    </>
                }
                type="info"
                showIcon
            />
        </Box>
    )
}
